import React from 'react';
import { useAuth } from '../hooks/useAuth';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';

import SEO from '../components/SEO';
import IndexSection from '../components/Landing/Person/Index/Index';
import birdIndex from '../../content/assets/birdIndex.svg';
import { StaticImage } from 'gatsby-plugin-image';
const BannerSection = loadable(() => import('../components/Landing/Person/Banner/Banner'));
const ExcellenceSection = loadable(
  () => import('../components/Landing/Person/Excellence/Excellence')
);
const TestimonialsSection = loadable(
  () => import('../components/Landing/Person/Testimonials/Testimonials')
);
const TechnologiesSection = loadable(
  () => import('../components/Landing/Person/Technologies/Technologies')
);
const Advantage = loadable(() => import('../components/Landing/Person/Advantages/Advantages'));
const FeaturedCustomers = loadable(
  () => import('../components/Landing/Person/FeaturedCustomers/FeaturedCustomers')
);
const Steps = loadable(() => import('../components/Landing/Person/Steps/Steps'));

const PAGE_TITLE = 'Envíos de dinero al extranjero';
const PAGE_DESCRIPTION =
  'Envía Dólares y divisas locales desde Chile a más de 170 países. Rápido, conveniente y 100% en línea. ¡Cotiza tu transferencia internacional con nosotros!';

const IndexPage = ({ location }: { location: any }) => {
  const { logout } = useAuth();
  const params = new URLSearchParams(location.search);
  const code = params.get('code');

  React.useEffect(() => {
    if (code === 'logout') {
      logout();
    }
  }, []);

  return (
    <>
      <SEO title={PAGE_TITLE} description={PAGE_DESCRIPTION} withChile />
      <div className="landing-page">
        <IndexSection>
          <StaticImage
            id="birdIndex"
            src="../../content/assets/birdIndex.svg"
            alt="landing bird"
            placeholder="blurred"
            quality={100}
            imgStyle={{ height: 'auto', width: '100%' }}
            height={410}
            layout="constrained"
          />
        </IndexSection>
        <BannerSection />
        <TestimonialsSection />
        <Steps />
        <Advantage />
        <ExcellenceSection />
        <FeaturedCustomers />
        <TechnologiesSection />
      </div>
    </>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
