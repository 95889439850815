import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: '#fff',
      display: 'flex',
      flexDirection: 'column',
      color: '#074F71',
      marginTop: 72,
      paddingTop: 83,
      paddingBottom: 100,
    },
    birdIndex: {
      margin: '0 auto',
      height: '410px',
      width: 'auto',
      //maxWidth: '512px',
    },
    availableText: {
      marginRight: 10,
    },
    storeIcon: {
      marginRight: 5,
    },
    appLinksContainer: {
      marginTop: 15,
    },
    appContainer: {
      marginTop: 30,
    },
    title: {
      fontSize: '48px',
      fontWeight: 300,
      '& span': {
        fontWeight: 500,
      },
      marginBottom: '26px',
      lineHeight: '58px',
      margin: 0,
    },
    firstSubSection: {
      '& h1': {
        margin: 0,
      },
      '& h2': {
        margin: 0,
        marginBottom: 20,
        fontWeight: 300,
        lineHeight: `30px`,
        fontSize: '22px',
        paddingRight: '10%',
      },
    },
    quote: {
      width: `80%`,
    },
    firstSubSectionTitle: {
      marginBottom: 25,
      maxWidth: '390px',
    },
    firstSubSectionSubTitle: {
      maxWidth: '450px',
    },
    secondSubSection: {
      marginTop: 72,
      marginBottom: 58,
      '& .MuiGrid-grid-lg-2': {
        textAlign: 'center',
      },
    },
    regulationsText: {
      fontWeight: 'bold',
      fontSize: '18px',
      marginBottom: '10px',
      textAlign: 'left',
      lineHeight: '28px',
    },
    regulationsLinks: {
      color: '#074F71',
      fontSize: '18px',
    },
    transferButton: {
      background: '#FAB234',
      border: 0,
      textAlign: 'center',
      textTransform: 'capitalize',
      color: '#074F71',
      borderRadius: '40px',
      padding: '0px 25px',
      fontSize: '22px',
      width: 214,
      height: 59,
      fontFamily: `Montserrat`,
      fontWeight: `bold`,
      '&:hover': {
        background: '#074F71',
        color: '#FAB234',
        boxShadow: '0px 2px 10px #888888',
      },
      [theme.breakpoints.down(1364)]: {
        fontSize: 18,
      },
      margin: '0 auto',
    },
    transferGroup: {
      width: `20%`,
      paddingLeft: `2%`,
      height: `100%`,
      paddingTop: `1%`,
    },
    transfer: {
      //height: '144px',
      display: 'flex',
      alignItems: 'center',
    },
    container: {
      padding: '0 6%',
      [theme.breakpoints.up(1921)]: {
        padding: 0,
      },
    },
    howWorks: {
      textDecoration: 'underline',
      fontSize: '22px',
      lineHeight: '30px',
      fontWeight: 600,
      color: '#F8B133',
    },
    [theme.breakpoints.down(1364)]: {
      root: {
        padding: '80px 0px',
      },
      title: {
        fontSize: '44px',
      },
      transferButton: {},
      firstSubSection: {
        marginBottom: '18px',
        '& h1': {
          marginBottom: '18px',
        },
        '& h2': {
          fontSize: '20px',
        },
      },
      secondSubSection: {
        marginBottom: '36px',
      },
      regulations: {
        '& .MuiGrid-grid-xs-12': {
          '&:not(:first-child)': {
            marginBottom: 'auto',
            textAlign: 'center',
          },
        },
      },
    },
    [theme.breakpoints.down(769)]: {
      root: {
        padding: '80px 0',
      },
      title: {
        fontSize: '50px',
        textAlign: 'center',
      },
      quote: {
        width: `100%`,
      },
      transferGroup: {
        width: '100%',
        height: 'auto',
        padding: '26px 0 0 0',
      },
      firstSubSectionTitle: {
        maxWidth: '100%',
        marginBottom: 25,
        paddingTop: 0,
      },
      firstSubSectionSubTitle: {
        maxWidth: '450px',
      },
      secondSubSection: {
        margin: 0,
        marginBottom: '49px',
      },
      transferButton: {
        height: '50px',
        width: '100%',
      },
      firstSubSection: {
        marginBottom: '30px',
        '& h1': {
          marginBottom: '24px',
        },
        '& h2': {
          padding: 0,

          marginBottom: 0,
          textAlign: 'center',
        },
      },
      regulations: {
        '& .MuiGrid-grid-xs-12': {
          textAlign: 'center',
          '&:not(:last-child)': {
            marginBottom: '16px',
          },
          '&:first-child': {
            display: 'flex',
            '& span': {
              width: '60%',
              margin: '0 auto',
            },
          },
        },
      },
      regulationsText: {
        textAlign: 'center',
      },
    },
    [theme.breakpoints.down(501)]: {
      root: {
        marginTop: '72px',
        padding: '48px 0',
      },
      title: {
        fontSize: '30px',
        fontWeight: 300,
        marginBottom: '22px',
        lineHeight: '32px',
        padding: '0 10px',
      },
      firstSubSection: {
        '& h1': {
          fontSize: '30px',
        },
        '& h2': {
          textAlign: 'center',
          fontSize: '14px',
          lineHeight: '19px',
          padding: '0 5%',
          '& span': {
            fontWeight: 700,
          },
        },
      },
      quote: {},
      transferButton: {
        width: '100%',
        height: '53px',
        fontSize: '22px',
      },
      regulationsText: {
        fontSize: '16px',
        marginBottom: '16px',
      },

      regulationsLinks: {
        fontSize: '12px',
      },
    },
  })
);

export default useStyles;
