export default {
  title: 'La ruta perfecta para transferir al extranjero',
  subtitle:
    'Simplificamos tus envíos de dinero para que no pierdas tiempo en procesos complejos, lo hagas 100% online y pagues un precio justo.',
  urls: {
    bancoCentral: {
      label: 'Ley Orgánica y Constitucional del Banco Central de Chile',
      url: 'https://www.bcentral.cl/contenido/-/detalle/ley-organica-constitucional-del-banco-central-de-chile-ley-n.c2b0-18.840-3',
    },
    prevencionDelito: {
      label: 'Ley N°20.393',
      url: 'https://www.bcn.cl/leychile/navegar?idNorma=1008668',
    },
    uaf: { label: 'Ley N°19.913', url: 'https://www.uaf.cl/legislacion/nuestra_ley.aspx' },
  },
  elements: {
    quote: { name: 'quote', label: 'Cotizador' },
    transferButton: { name: 'transferButton', label: '¡Transfiere!' },
    regulations: { name: 'regulations', label: 'Regulados por:' },
  },
};
